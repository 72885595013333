var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "el-button",
      class: [_vm.width, _vm.size, _vm.type, { disabled: _vm.disabled }],
      attrs: { plain: "" },
      on: {
        click: function ($event) {
          return _vm.$emit("button-clicked")
        },
      },
    },
    [
      _vm._t("default"),
      _c(
        "div",
        { staticStyle: { "font-family": "'TT Firs Neue', sans-serif" } },
        [_vm._v(_vm._s(_vm.title))]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }