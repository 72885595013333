<template>
  <div class="techSupport">
    <span class="techSupport__title">
      Если возникли вопросы - позвоните в тех.поддержку
    </span>
    <span class="techSupport__phone">{{ techSupportPhone }}</span>
  </div>
</template>

<script>
import { techSupportPhone } from '@/constants/mainData'
export default {
  name: 'TechSupport',
  data() {
    return {
      techSupportPhone,
    }
  },
}
</script>

<style lang="sass" scoped>
.techSupport
  display: flex
  flex-direction: column
  text-align: center
  &__title
    font-family: $fontPrimary
    color: $greyText
    font-weight: 500
    font-size: 20px
  &__phone
    font-family: $fontPrimary
    color: $special
    font-weight: 500
    font-size: 20px
</style>
