<template>
  <div
    class="el-button"
    plain
    :class="[width, size, type, { disabled: disabled }]"
    @click="$emit('button-clicked')"
  >
    <slot />
    <div style="font-family: 'TT Firs Neue', sans-serif">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'MainButton',
  props: {
    title: {
      type: String,
      default: '',
    },
    // три варианта - form, full, half
    width: {
      type: String,
      default: 'full',
    },
    // два варианта - big/small
    size: {
      type: String,
      default: 'big',
    },
    // два варианта - primary/secondary
    type: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked() {
      if (!this.disabled) {
        this.$emit('button-clicked')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.el-button
  display: flex
  justify-content: center
  align-items: center
  font-family: $fontPrimary
  font-weight: 500
  border-radius: 50px
  cursor: pointer
  &.form
    width: 280px
  &.full
    width: 500px
  &.half
    width: 50%
  &.primary
    border: none
    background: $special
    color: $color-white
  &.secondary
    background: transparent
    border: 2px solid $special
    color: $special
  &.big
    height: 66px
    font-size: 20px
  &.small
    height: 60px
    font-size: 18px
  &.disabled
    opacity: 40%
    cursor: not-allowed
.el-button.primary:hover, .el-button.secondary:hover
  background: $hover
  border-color: $hover
  color: $color-white
.el-button.primary:active, .el-button.secondary:active
  background: $visited
  border-color: $visited
  color: $color-white
</style>
