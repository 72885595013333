<template>
  <section class="page">
    <div>
      <div class="page__header">
        <div class="page__header-title">Статус</div>
        <div class="page__header-status">В пути</div>
      </div>

      <TimeslotCard :timeslot="timeslot" />

      <div class="flex">
        <MainButton
          title="Подтвердить приезд"
          type="primary"
          size="big"
          width="full"
          class="confirm-button"
          @button-clicked="confirm"
        />
      </div>
    </div>

    <TechSupport />
  </section>
</template>

<script>
import { GET_IS_INCOMPLETE_PARKING } from '@/store/actions'
import { mapGetters } from 'vuex'
import API from '@/api'
import MainButton from '@/views/terminalUI/components/UI/buttons/MainButton'
import TechSupport from '@/views/terminalUI/components/TechSupport'
import TimeslotCard from '@/views/terminalUI/components/TimeslotCard'

export default {
  name: 'IntimeArrived',
  components: {
    MainButton,
    TechSupport,
    TimeslotCard,
  },
  data() {
    return {
      timeslot: null,
    }
  },
  computed: {
    ...mapGetters({ hasIncompleteParking: GET_IS_INCOMPLETE_PARKING }),
  },
  created() {
    this.timeslot = this.$route.params.timeslot
  },
  methods: {
    confirm() {
      const data = {
        timeslot_id: this.timeslot.id,
        unload_id: this.timeslot.unload_id,
        plate_truck: this.timeslot.plate_truck,
        from_hw_terminal: 1,
      }

      let method = this.hasIncompleteParking
        ? 'toStatusArrived'
        : 'timeslotStatusParkedFar'

      API.timeslots[method](data)
        .then(() => {
          this.$router.push({
            name: 'terminal-finallyResponse',
            params: { timeslot: this.timeslot },
          })
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style lang="sass" scoped>
.page
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 60px 11% 20px
  &__header
    display: flex
    justify-content: space-between
    &-title
      margin-bottom: 40px
      font-family: $fontPrimary
      font-size: 48px
      font-weight: 500
      line-height: 56px
    &-status
      padding: 0 43px
      height: 46px
      border-radius: 40px
      display: flex
      align-items: center
      justify-content: center
      font-weight: 500
      font-size: 20px
      background-color: $color-blue-secondary
      color: $color-white

  .flex
    display: flex
    gap: 32px
  .confirm-button
    margin: 0 auto
</style>
