var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "techSupport" }, [
    _c("span", { staticClass: "techSupport__title" }, [
      _vm._v(" Если возникли вопросы - позвоните в тех.поддержку "),
    ]),
    _c("span", { staticClass: "techSupport__phone" }, [
      _vm._v(_vm._s(_vm.techSupportPhone)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }