var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "page" },
    [
      _c(
        "div",
        [
          _vm._m(0),
          _c("TimeslotCard", { attrs: { timeslot: _vm.timeslot } }),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c("MainButton", {
                staticClass: "confirm-button",
                attrs: {
                  title: "Подтвердить приезд",
                  type: "primary",
                  size: "big",
                  width: "full",
                },
                on: { "button-clicked": _vm.confirm },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("TechSupport"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page__header" }, [
      _c("div", { staticClass: "page__header-title" }, [_vm._v("Статус")]),
      _c("div", { staticClass: "page__header-status" }, [_vm._v("В пути")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }